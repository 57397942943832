/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContributorBack } from '../models/ContributorBack';
import type { CreatorBack } from '../models/CreatorBack';
import type { InviteContributorData } from '../models/InviteContributorData';
import type { InviteCreatorData } from '../models/InviteCreatorData';
import type { Page_UserBack_ } from '../models/Page_UserBack_';
import type { ProBack } from '../models/ProBack';
import type { ProBackData } from '../models/ProBackData';
import type { UserBack } from '../models/UserBack';
import type { UserBackDetail } from '../models/UserBackDetail';
import type { UserRole } from '../models/UserRole';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserBackService {

    /**
     * Get Current User Back
     * @returns UserBack Successful Response
     * @throws ApiError
     */
    public static getCurrentUserBack(): CancelablePromise<UserBack> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/users/me',
        });
    }

    /**
     * Get User Back
     * @param userId
     * @returns UserBackDetail Successful Response
     * @throws ApiError
     */
    public static getUserBack(
        userId: string,
    ): CancelablePromise<UserBackDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/user',
            query: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Role
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyRole(
        requestBody: UserRole,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Users Back
     * @param email
     * @param page
     * @param size
     * @returns Page_UserBack_ Successful Response
     * @throws ApiError
     */
    public static getUsersBack(
        email: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_UserBack_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/users',
            query: {
                'email': email,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Creators
     * @returns CreatorBack Successful Response
     * @throws ApiError
     */
    public static getCreators(): CancelablePromise<Array<CreatorBack>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/creator',
        });
    }

    /**
     * Invite Creator
     * @param requestBody
     * @returns CreatorBack Successful Response
     * @throws ApiError
     */
    public static inviteCreator(
        requestBody: InviteCreatorData,
    ): CancelablePromise<CreatorBack> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/creator',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Invite Contributor
     * @param requestBody
     * @returns ContributorBack Successful Response
     * @throws ApiError
     */
    public static inviteContributor(
        requestBody: InviteContributorData,
    ): CancelablePromise<ContributorBack> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/contributor',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pending Pros
     * @returns ProBack Successful Response
     * @throws ApiError
     */
    public static getPendingPros(): CancelablePromise<Array<ProBack>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/pro',
        });
    }

    /**
     * Modify Pending Pros
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyPendingPros(
        requestBody: ProBackData,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/pro',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
