/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ProStatus {
    NONE = 'NONE',
    PENDING = 'PENDING',
    VERIFIED = 'VERIFIED',
    REFUSED = 'REFUSED',
}
