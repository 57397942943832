import {Box, useTheme} from '@mui/material';

import Image from 'next/image';
import {STORAGE} from '../../utils/settings';
import {useResponsive} from '../../provider/MediaQueryProvider';

export function LogoNav() {
    const theme = useTheme();
    const {isMobile, isTablet} = useResponsive();
    const picture = STORAGE + '/logo/logo_nav_' + theme.palette.mode + '.svg';

    const height = isTablet || isMobile ? 20 : 35;
    const width = isTablet || isMobile ? 118 : 207;

    return <Image src={picture} alt={'Kunekune'} width={width} height={height} priority />;
}

export function LogoHome() {
    const theme = useTheme();
    const {isMobile, isTablet, isLaptop} = useResponsive();
    const picture = STORAGE + '/logo/logo_nav_' + theme.palette.mode + '.svg';

    const height = isMobile ? 40 : isTablet ? 50 : isLaptop ? 65 : 87;
    const width = isMobile ? 235.2 : isTablet ? 295 : isLaptop ? 386 : 517;

    return <Image src={picture} alt={'Kunekune'} width={width} height={height} priority />;
}

export function LogoFooter() {
    const theme = useTheme();
    const {isMobile, isTablet} = useResponsive();
    const picture = STORAGE + '/logo/logo_footer_' + theme.palette.mode + '.svg';

    return <Image src={picture} alt={'Kunekune'} width={265} height={103} priority />;
}

export function Paypal(props: CardProps) {
    const picture = 'https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-color.svg';

    const height = props.height ?? 50;
    const width = Math.floor(height * 3.84);

    return (
        <picture style={{cursor: 'pointer'}}>
            <source srcSet={picture} type="image/png" />
            <img
                src={picture}
                height={height}
                width={width}
                referrerPolicy="no-referrer"
                alt="current"
            />
        </picture>
    );
}

type IconCustomProps = {
    name: string;
    title: string;
    onClick: () => void;
};

export function IconCustom(props: IconCustomProps) {
    const picture = STORAGE + '/icon/' + props.name + '.png';

    return (
        <Box
            onClick={props.onClick}
            sx={{cursor: 'pointer', marginLeft: '5px', marginRight: '5px'}}
        >
            <Image src={picture} alt={props.title} width={24} height={24} />
        </Box>
    );
}

type CardProps = {
    height?: number | null | undefined;
};

export function Visa(props: CardProps) {
    const picture = STORAGE + '/icon/visa.svg';

    const height = props.height ?? 30;
    const width = Math.floor(height * 3.08); // Image source ratio

    return <Image src={picture} alt={'VISA'} width={width} height={height} />;
}

export function Mastercard(props: CardProps) {
    const picture = STORAGE + '/icon/mastercard.svg';

    const height = props.height ?? 30;
    const width = Math.floor(height * 1.28); // Image source ratio

    return <Image src={picture} alt={'Mastercard'} width={width} height={height} />;
}

export function Amex(props: CardProps) {
    const picture = STORAGE + '/icon/amex.svg';

    const size = props.height ?? 30;

    return <Image src={picture} alt={'American Express'} width={size} height={size} />;
}
