import '../styles/globals.css';
import 'suneditor/dist/css/suneditor.min.css';

import {Box, CssBaseline, ThemeProvider, createTheme} from '@mui/material';
import {IS_DARK_THEME, getDark, gtm} from '../utils/settings';
import {NextComponentType, NextPageContext} from 'next';
import {QueryClient, QueryClientProvider} from 'react-query';
import {useEffect, useMemo, useState} from 'react';

import {AppCacheProvider} from '@mui/material-nextjs/v13-pagesRouter';
import {AppProps} from 'next/app';
import Bar from '../components/navigation/bar';
import {BasketProvider} from '../provider/BasketProvider';
import {Colors} from '../components/common/colors';
import {ConfirmationProvider} from '../provider/ConfirmationProvider';
import Footer from '../components/navigation/footer';
import {NotificationProvider} from '../provider/NotificationProvider';
import {Roboto} from 'next/font/google';
import Script from 'next/script';
import {SessionProvider} from 'next-auth/react';
import {UserProvider} from '../provider/UserProvider';
import {appWithTranslation} from 'next-i18next';
import dynamic from 'next/dynamic';
import {getAcceptation} from '../utils/cookie';
import {useRouter} from 'next/router';

const roboto = Roboto({
    weight: ['300', '400', '500', '700'],
    style: ['normal', 'italic'],
    subsets: ['latin'],
    display: 'swap',
});

const NextNProgress = dynamic(() => import('nextjs-progressbar'));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true, // default: true
            refetchOnMount: true, // default: always
            staleTime: 60000,
        },
    },
});

type MiniPageProps = {
    getLayout?: (page: React.ReactNode) => JSX.Element;
};

type MyAppProps = AppProps & {
    Component: NextComponentType<NextPageContext, any, any> & MiniPageProps;
};

// This default export is required in a new `pages/_app.js` file.
function MyApp({Component, pageProps, ...otherProps}: MyAppProps) {
    const consent = getAcceptation();

    if (Component?.getLayout) {
        return Component.getLayout(<Component {...pageProps} />);
    }

    return (
        <>
            <Script
                id="gtag"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html:
                        `

                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                
                gtag('consent', 'default', {
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied'
                });

                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','` +
                        gtm +
                        `');`,
                }}
            />
            {consent === true && (
                <Script
                    id="consupd"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
              gtag('consent', 'update', {
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
              });
            `,
                    }}
                />
            )}
            <NextNProgress />
            <AppCacheProvider {...otherProps}>
                <QueryClientProvider client={queryClient}>
                    <UserProvider>
                        <SessionProvider session={pageProps.session}>
                            <BasketProvider>
                                <Layout>
                                    <main className={roboto.className}>
                                        <Component {...pageProps} />
                                    </main>
                                </Layout>
                            </BasketProvider>
                        </SessionProvider>
                    </UserProvider>
                </QueryClientProvider>
            </AppCacheProvider>
        </>
    );
}

export default appWithTranslation(MyApp);

declare module '@mui/material/styles' {
    interface Palette {
        discord: Palette['primary'];
        yellow: Palette['primary'];
        yellowHover: Palette['primary'];
    }

    interface PaletteOptions {
        discord?: PaletteOptions['primary'];
        yellow?: PaletteOptions['primary'];
        yellowHover?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    export interface ButtonPropsColorOverrides {
        discord: true;
    }
}

function Layout({children}: {children: JSX.Element}) {
    const [isDarkTheme, setIsDarkTheme] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            const dark = getDark();
            setIsDarkTheme(dark);
        }, 1000);
    });

    const theme = useMemo(
        () =>
            createTheme({
                typography: {
                    fontFamily: roboto.style.fontFamily,
                    button: {
                        textTransform: 'none',
                    },
                },
                palette: {
                    mode: isDarkTheme ? 'dark' : 'light',
                    primary: {
                        main: isDarkTheme ? '#000' : Colors.first,
                        dark: isDarkTheme ? Colors.grey : Colors.first,
                        light: isDarkTheme ? Colors.grey : Colors.first,
                        // contrastText: '#000',
                    },
                    secondary: {
                        main: isDarkTheme ? Colors.dark : Colors.light,
                        dark: isDarkTheme ? Colors.dark : '#000',
                        light: isDarkTheme ? Colors.dark : '#FFF',
                        contrastText: isDarkTheme ? '#FFF' : Colors.grey,
                    },
                    background: {
                        default: isDarkTheme ? '#1c1c1e' : '#FFF',
                    },
                    discord: {
                        main: '#5865F2',
                    },
                    yellow: {
                        main: Colors.yellow,
                    },
                    yellowHover: {
                        main: Colors.yellowHover,
                    },
                    // warning: {
                    //     main: '#000',
                    //     dark: '#000',
                    //     light: '#000',
                    //     contrastText: '#000',
                    // },
                    // error: {
                    //     main: '#000',
                    //     dark: '#000',
                    //     light: '#000',
                    //     contrastText: '#000',
                    // },
                    // info: {
                    //     main: '#000',
                    //     dark: '#000',
                    //     light: '#000',
                    //     contrastText: '#000',
                    // },
                    // success: {
                    //     main: '#000',
                    //     dark: '#000',
                    //     light: '#000',
                    //     contrastText: '#000',
                    // },
                },
                // components: {
                //     // Name of the component
                //     MuiButtonBase: {
                //         defaultProps: {
                //             // The props to change the default for.
                //             // disableRipple: true, // No more ripple, on the whole application 💣!
                //             sx: {border: '1px solid #fa1', boxShadow: 0},
                //         },
                //     },
                // },
                // shadows: Array(25).fill('none') as Shadows,
                // IMPORTANT! Breakpoints should match media queries defined in: provider/MediaQueryProvider.tsx
                breakpoints: {
                    values: {
                        xs: 0, // mobile
                        sm: 450, // tablet
                        md: 768, // laptop
                        lg: 1024, // wide
                        xl: 1280, // desktop
                    },
                },
            }),
        [isDarkTheme]
    );

    function toggleTheme() {
        const newIsDarkTheme = !isDarkTheme;
        setIsDarkTheme(newIsDarkTheme);
        localStorage.setItem(IS_DARK_THEME, newIsDarkTheme.toString());
    }

    useEffect(() => {
        const isDarkThemeLocal = localStorage.getItem(IS_DARK_THEME);
        const prefersDark =
            window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        if (isDarkThemeLocal) {
            setIsDarkTheme(isDarkThemeLocal == 'true');
        } else if (prefersDark) {
            setIsDarkTheme(true);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <NotificationProvider>
                <ConfirmationProvider>
                    <HeaderFooterProvider isDarkTheme={isDarkTheme} toggleTheme={toggleTheme}>
                        {children}
                    </HeaderFooterProvider>
                </ConfirmationProvider>
            </NotificationProvider>
        </ThemeProvider>
    );
}

type HeaderFooterProviderProps = {
    toggleTheme: () => void;
    isDarkTheme: boolean;
    children: JSX.Element;
};

function HeaderFooterProvider(props: HeaderFooterProviderProps) {
    const router = useRouter();

    const isExcluded = EXCLUDE_PATHS.includes(router.pathname);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            {!isExcluded && (
                <Bar isDarkTheme={props.isDarkTheme} toggleTheme={props.toggleTheme} />
            )}
            {/* {isShopping && <ShoppingBar />} */}
            <Box sx={{flex: 1}}>{props.children}</Box>
            {!isExcluded && <Footer />}
        </Box>
    );
}

const EXCLUDE_PATHS = [
    '/mini/[url]' /*, '/[url]/basket', '/[url]/connect', '/[url]/payment'*/,
];
const SHOPPING_PATHS = ['/[url]/basket', '/[url]/connect', '/[url]/payment'];
