// type TwitchIconProps = {
//     // width: number;
//     // height: number;
//     // color: string;
// };

// export default function TwitchIcon(props: TwitchIconProps) {
//     return (
//         <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
//             <path
//                 d="M5.7 0L1.4 10.985V55.88h15.284V64h8.597l8.12-8.12h12.418l16.716-16.716V0H5.7zm51.104 36.3L47.25 45.85H31.967l-8.12 8.12v-8.12H10.952V5.73h45.85V36.3zM47.25 16.716v16.716h-5.73V16.716h5.73zm-15.284 0v16.716h-5.73V16.716h5.73z"
//                 fill="#6441a4"
//                 fill-rule="evenodd"
//             />
//         </svg>
//     );
// }

import {SvgIcon} from '@mui/material';

// export default function TwitchIcon() {
//     return (
//         <Icon
//             style={{
//                 display: 'flex',
//                 height: 'inherit',
//                 width: 'inherit',
//             }}
//         >
//             <img
//                 style={{
//                     textAlign: 'center',
//                 }}
//                 src="https://storage.sbg.cloud.ovh.net/v1/AUTH_857d29cf94c54215b0da86e11c623b12/crowdfunding/icon/twitch.svg"
//             />
//         </Icon>
//     );
// }

export default function TiktokIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z" />
        </SvgIcon>
    );
}
