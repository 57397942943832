import {useMutation, useQuery} from 'react-query';
import {
    UserService,
    UserCreate,
    User,
    ApiError,
    UserContact,
    Body_modify_picture,
} from '../types';
import {UserIdentity} from '../types/models/UserIdentity';
import {UserPro} from '../types/models/UserPro';
import {USER_GET} from './keys';

export function useCreateUser() {
    return useMutation<User, ApiError, UserCreate>((params: UserCreate) =>
        UserService.createUser(params)
    );
}

export function useGetUser(enabled: boolean) {
    return useQuery(USER_GET, UserService.getUser, {
        enabled: enabled,
        refetchOnMount: true,
    });
}

export function useRemovePicture() {
    return useMutation<boolean, ApiError, void>(() => UserService.removePicture());
}

export function useModifyPicture() {
    return useMutation<string, ApiError, Body_modify_picture>((body: Body_modify_picture) =>
        UserService.modifyPicture(body)
    );
}

export function useModifyIdentity() {
    return useMutation<boolean, ApiError, UserIdentity>((userIdentity: UserIdentity) =>
        UserService.modifyIdentity(userIdentity)
    );
}

export function useModifyPro() {
    return useMutation<boolean, ApiError, UserPro>((userPro: UserPro) =>
        UserService.modifyPro(userPro)
    );
}

export function useModifyContact() {
    return useMutation<boolean, ApiError, UserIdentity>((userContact: UserContact) =>
        UserService.modifyContact(userContact)
    );
}

export function useModifyEmail() {
    return useMutation<boolean, ApiError, string>((email: string) =>
        UserService.modifyEmail(email)
    );
}

export function useModifyLang() {
    return useMutation<boolean, ApiError, string>((lang: string) =>
        UserService.modifyLang(lang)
    );
}

export function useDeleteUser() {
    return useMutation<boolean, ApiError, void>(() => UserService.deleteUser());
}
