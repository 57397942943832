/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InputDiscordUser } from '../models/InputDiscordUser';
import type { InputDiscordUserConnect } from '../models/InputDiscordUserConnect';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DiscordService {

    /**
     * Link User
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static linkUser(
        requestBody: InputDiscordUser,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/discord/link-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * On User Connected
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static onUserConnected(
        requestBody: InputDiscordUserConnect,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/discord/user-connected',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
