import {Box, IconButton, Modal, ModalProps} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import {TabTitle} from './text';
import {useResponsive} from '../../provider/MediaQueryProvider';

export function Popup(props: ModalProps & {children: React.ReactNode}) {
    const {isLaptop, isTablet, isMobile} = useResponsive();
    const maxWidth = isLaptop ? '750px' : isTablet ? '450px' : isMobile ? '350px' : '1000px';
    const padding = isTablet || isMobile ? '5px' : '15px';
    return (
        <Modal
            aria-labelledby={props.title}
            aria-describedby={props.title}
            {...props}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                    padding: padding,
                    maxWidth: maxWidth,
                    maxHeight: '90vh',
                    outline: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box display={'flex'} flexDirection="row" justifyContent="flex-end">
                    {props.title && <TabTitle>{props.title}</TabTitle>}
                    <IconButton
                        aria-label="close"
                        onClick={() => props.onClose({}, 'backdropClick')}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        overflowY: 'auto',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    }}
                >
                    {props.children}
                </Box>
            </Box>
        </Modal>
    );
}
