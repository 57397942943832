import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LoginIcon from '@mui/icons-material/Login';
import {
    Box,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Switch,
} from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HomeIcon from '@mui/icons-material/Home';
import {useTranslation} from 'next-i18next';
import User from './user';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {LogoNav} from './logo';
import {Colors} from '../common/colors';
import Lang from './lang';
import Link from 'next/link';

type BarProps = {
    toggleTheme: () => void;
    isDarkTheme: boolean;
};

export default function Bar(props: BarProps) {
    return (
        <AppBar
            position="sticky"
            sx={{
                bgcolor: 'secondary.light',
                height: '50px',
                backgroundImage: 'none',
                boxShadow: '0px 0px 12px rgb(0 0 0 / 14%)',
            }}
        >
            <Toolbar
                variant="dense"
                sx={{
                    paddingLeft: '0px',
                }}
            >
                <Box
                    display={'flex'}
                    flex={'1'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    height={'50px'}
                >
                    <Brand {...props} />
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <User />
                        <Lang />
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

function Brand(props: BarProps) {
    const {isMobile, isTablet} = useResponsive();
    const [drawerOpen, setDrawerOpen] = useState<boolean>();
    return (
        <Box display={'flex'} flexDirection={'row'} alignItems="center">
            {/* <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{mr: 2}}
                onClick={() => setDrawerOpen(!drawerOpen)}
            >
                <MenuIcon />
            </IconButton> */}
            <Drawer
                anchor={'left'}
                open={drawerOpen}
                onClose={() => setDrawerOpen(!drawerOpen)}
            >
                <LeftMenu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            </Drawer>
            <DarkModeSwitcher {...props} />
            <IconButton
                component={Link}
                href="/"
                color="primary"
                sx={{marginLeft: isMobile ? '5px' : '10px', padding: isMobile ? '2px' : '4px'}}
            >
                <LogoNav />
            </IconButton>
        </Box>
    );
}

type LeftMenuProps = {
    drawerOpen: boolean;
    setDrawerOpen: (open: boolean) => void;
};

function LeftMenu(props: LeftMenuProps) {
    const {t} = useTranslation('common');

    return (
        <Box
            role="presentation"
            onClick={() => props.setDrawerOpen(!props.drawerOpen)}
            onKeyDown={() => props.setDrawerOpen(!props.drawerOpen)}
        >
            <List>
                <ListItem key={'Home'} disablePadding>
                    <ListItemButton component={Link} href="/">
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Accueil'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem key={'Login'} disablePadding>
                    <ListItemButton component={Link} href="/login">
                        <ListItemIcon>
                            <LoginIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('action.login')} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Register'} disablePadding>
                    <ListItemButton component={Link} href="/register">
                        <ListItemIcon>
                            <HowToRegIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('action.register')} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Faq'} disablePadding>
                    <ListItemButton component={Link} href="/help/faq">
                        <ListItemIcon>
                            <QuestionAnswerIcon />
                        </ListItemIcon>
                        <ListItemText primary={'FAQ'} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Support'} disablePadding>
                    <ListItemButton component={Link} href="/help/support">
                        <ListItemIcon>
                            <ContactSupportIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Support'} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );
}

type DarkModeSwitcherProps = {
    toggleTheme: () => void;
    isDarkTheme: boolean;
};

function DarkModeSwitcher(props: DarkModeSwitcherProps) {
    const {isMobile} = useResponsive();
    const {t} = useTranslation('common');

    const iconSx = isMobile
        ? {color: '#000', width: 25, margin: 'auto'}
        : {color: '#000', marginLeft: 1};

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            style={{
                // backgroundColor: props.isDarkTheme ? '#3f3f3f' : Colors.first,
                borderRadius: 15,
                height: 25,
            }}
        >
            {/* {props.isDarkTheme ? (
                <DarkModeOutlinedIcon
                    sx={iconSx}
                    fontSize="small"
                    onClick={props.toggleTheme}
                />
            ) : (
                <LightModeOutlinedIcon
                    sx={iconSx}
                    fontSize="small"
                    onClick={props.toggleTheme}
                />
            )} */}
            <Android12Switch
                // sx={{color: 'white', marginRight: 1}}
                role="button"
                inputProps={{
                    'aria-label': 'Mode sombre',
                }}
                checked={props.isDarkTheme || false} // shorten blink time (for real)
                // size="small"
                onClick={props.toggleTheme}
            />
        </Box>
    );
}

const Android12Switch = styled(Switch)(({theme}) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        backgroundColor: theme.palette.mode == 'dark' ? Colors.grey : Colors.first,
        opacity: 1,
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-.46-.04-.92-.1-1.36-.98 1.37-2.58 2.26-4.4 2.26-2.98 0-5.4-2.42-5.4-5.4 0-1.81.89-3.42 2.26-4.4-.44-.06-.9-.1-1.36-.1z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                Colors.grey
            )}" d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zM2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1zm18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1zm0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zM5.99 4.58c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41L5.99 4.58zm12.37 12.37c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0 .39-.39.39-1.03 0-1.41l-1.06-1.06zm1.06-10.96c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06zM7.05 18.36c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06z" /></svg>')`,
            right: 12,
        },
    },
    '& .Mui-checked+.MuiSwitch-track': {
        backgroundColor:
            theme.palette.mode == 'dark' ? Colors.grey + ' !important' : Colors.first,
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));
