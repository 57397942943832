/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DailyCampaignData } from '../models/DailyCampaignData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatsBackService {

    /**
     * Get Orders Stats
     * @param force
     * @returns DailyCampaignData Successful Response
     * @throws ApiError
     */
    public static getOrdersStats(
        force: boolean = false,
    ): CancelablePromise<Array<DailyCampaignData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/stats/orders',
            query: {
                'force': force,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
