/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Network {
    YOUTUBE = 'YOUTUBE',
    FACEBOOK = 'FACEBOOK',
    INSTAGRAM = 'INSTAGRAM',
    TWITCH = 'TWITCH',
    TWITTER = 'TWITTER',
    TIKTOK = 'TIKTOK',
    WEBSITE = 'WEBSITE',
}
