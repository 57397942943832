/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthorBack } from '../models/AuthorBack';
import type { AuthorBackOverview } from '../models/AuthorBackOverview';
import type { Body_modify_author_picture } from '../models/Body_modify_author_picture';
import type { ModifyAuthorBackBio } from '../models/ModifyAuthorBackBio';
import type { ModifyAuthorBackNetwork } from '../models/ModifyAuthorBackNetwork';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthorBackService {

    /**
     * Modify Author Picture
     * @param formData
     * @returns string Successful Response
     * @throws ApiError
     */
    public static modifyAuthorPicture(
        formData: Body_modify_author_picture,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/author/picture',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Authors
     * @returns AuthorBackOverview Successful Response
     * @throws ApiError
     */
    public static getAuthors(): CancelablePromise<Array<AuthorBackOverview>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/author',
        });
    }

    /**
     * Modify Author
     * @param requestBody
     * @returns AuthorBackOverview Successful Response
     * @throws ApiError
     */
    public static modifyAuthor(
        requestBody: AuthorBack,
    ): CancelablePromise<AuthorBackOverview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/author',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Author Bio
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyAuthorBio(
        requestBody: ModifyAuthorBackBio,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/author/bio',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Author Network
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyAuthorNetwork(
        requestBody: ModifyAuthorBackNetwork,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/author/network',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
