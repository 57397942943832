/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CronHistoryBack } from '../models/CronHistoryBack';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CronJobService {

    /**
     * Execute Cron Job Forgotten Basket
     * @returns string Successful Response
     * @throws ApiError
     */
    public static executeCronJobForgottenBasket(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cron/job/forgotten-basket',
        });
    }

    /**
     * Execute Cron Job Expired Reservation
     * @returns string Successful Response
     * @throws ApiError
     */
    public static executeCronJobExpiredReservation(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cron/job/expired-reservation',
        });
    }

    /**
     * Get Cron Job History
     * @returns CronHistoryBack Successful Response
     * @throws ApiError
     */
    public static getCronJobHistory(): CancelablePromise<Array<CronHistoryBack>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cron/job/history',
        });
    }

}
