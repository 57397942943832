/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum OrderStatus {
    WAITING = 'WAITING',
    CONFIRMED = 'CONFIRMED',
    SHIPPED = 'SHIPPED',
    DELIVERED = 'DELIVERED',
    CANCELED_BY_USER = 'CANCELED_BY_USER',
    CANCELED_BY_WEBHOOK = 'CANCELED_BY_WEBHOOK',
    CANCELED_BY_BACKOFFICE = 'CANCELED_BY_BACKOFFICE',
    CANCELED = 'CANCELED',
    ERROR = 'ERROR',
}
