import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Box, Button, Menu, Typography} from '@mui/material';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {useState} from 'react';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {useUser} from '../../provider/UserProvider';
import {useModifyLang} from '../../queries/userQueries';
import {Colors} from '../common/colors';
import {EnglishFlagIcon, FrenchFlagIcon} from '../icons/flags';
import {useTranslation} from 'next-i18next';

export default function Lang() {
    const {dbUser} = useUser();
    const modifyLang = useModifyLang();
    const {isMobile} = useResponsive();
    const router = useRouter();
    const {i18n} = useTranslation();

    const lngs = {
        fr: {nativeName: 'Français', flag: <FrenchFlagIcon />},
        en: {nativeName: 'English', flag: <EnglishFlagIcon />},
    };

    async function switchLang(lng: string) {
        if (dbUser) {
            await modifyLang.mutateAsync(lng);
        }

        const {pathname, asPath, query} = router;
        router.push({pathname, query}, asPath, {locale: lng});
        handleClose();
        // document.location.href = url;
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                sx={{
                    // marginLeft: 1,
                    // padding: 0,
                    // display: 'block',
                    backgroundColor: isMobile ? 'transparent' : Colors.light,
                    height: '32px',
                    width: isMobile ? '32px' : '50px',
                    minWidth: isMobile ? '32px' : '64px',
                    textAlign: 'center',
                    '&:hover': {
                        // backgroundColor: Colors.dark,
                    },
                }}
                onClick={handleClick}
            >
                {lngs[router.locale].flag}
                {!isMobile && <ArrowDropDownIcon sx={{color: '#000', height: '32px'}} />}
            </Button>
            <Head>
                <meta property="og:locale" content={router.locale ?? 'fr'} />
            </Head>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    sx: {backgroundColor: 'white', backgroundImage: 'none'},
                }}
            >
                <Box
                    sx={{
                        // borderLeft: '1px solid #ccc',
                        // borderRight: '1px solid #ccc',
                        // borderTop: '1px solid #ccc',
                        backgroundColor: 'white',
                    }}
                >
                    {Object.keys(lngs).map((lng) => (
                        <Box
                            display="flex"
                            flexDirection={'row'}
                            key={lng}
                            sx={{
                                cursor: 'pointer',
                                textAlign: 'center',
                                padding: '5px',
                                height: '42px',
                                '&:hover p': {
                                    textDecoration: 'underline',
                                },
                                '&:hover img': {
                                    opacity: '1',
                                },
                                // borderBottom: '1px solid #ccc',
                            }}
                            onClick={() => switchLang(lng)}
                        >
                            <Box sx={{width: 32, height: 32}}>{lngs[lng].flag}</Box>
                            <Typography
                                fontSize={12}
                                display={'block'}
                                alignSelf="center"
                                marginLeft={1}
                                color={'black'}
                            >
                                {lngs[lng].nativeName}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Menu>
        </>
    );
}

export function BoxSx() {
    return (
        <Box
            sx={{
                width: 300,
                height: 300,
                backgroundColor: 'primary.dark',
                '&:hover': {
                    backgroundColor: 'primary.main',
                    opacity: [0.9, 0.8, 0.7],
                },
            }}
        />
    );
}
