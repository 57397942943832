/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Role {
    CONTRIBUTOR = 'CONTRIBUTOR',
    CREATOR = 'CREATOR',
    CLIENT = 'CLIENT',
    ADMIN = 'ADMIN',
    SUPERADMIN = 'SUPERADMIN',
}
