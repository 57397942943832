import Image from 'next/image';
import {STORAGE} from '../../utils/settings';

export function FrenchFlagIcon() {
    const src = STORAGE + '/flag/' + 'france.png';
    return <Image src={src} alt={'France'} width={32} height={32} />;
}

export function EnglishFlagIcon() {
    const src = STORAGE + '/flag/' + 'united-kingdom.png';
    return <Image src={src} alt={'United Kingdom'} width={32} height={32} />;
}
