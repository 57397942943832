/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSupportCategoryData } from '../models/CreateSupportCategoryData';
import type { CreateSupportData } from '../models/CreateSupportData';
import type { FaqBackCategory } from '../models/FaqBackCategory';
import type { Page_SupportBack_ } from '../models/Page_SupportBack_';
import type { ReadSupportData } from '../models/ReadSupportData';
import type { StatusSupportData } from '../models/StatusSupportData';
import type { SupportBack } from '../models/SupportBack';
import type { SupportBackCategory } from '../models/SupportBackCategory';
import type { SupportStatus } from '../models/SupportStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SupportBackService {

    /**
     * Modify Support Campaign
     * @param requestBody
     * @returns SupportBackCategory Successful Response
     * @throws ApiError
     */
    public static modifySupportCampaign(
        requestBody: CreateSupportData,
    ): CancelablePromise<SupportBackCategory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/support',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Support Category Back
     * @param campaignId
     * @returns SupportBackCategory Successful Response
     * @throws ApiError
     */
    public static getSupportCategoryBack(
        campaignId: string,
    ): CancelablePromise<Array<SupportBackCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/support/category',
            query: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Support Category
     * @param requestBody
     * @returns SupportBackCategory Successful Response
     * @throws ApiError
     */
    public static modifySupportCategory(
        requestBody: CreateSupportCategoryData,
    ): CancelablePromise<SupportBackCategory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/support/category',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Faq Back
     * @returns FaqBackCategory Successful Response
     * @throws ApiError
     */
    public static getFaqBack(): CancelablePromise<Array<FaqBackCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/faq',
        });
    }

    /**
     * Get Support Back
     * @param campaignId
     * @param supportStatus
     * @param page
     * @param size
     * @returns Page_SupportBack_ Successful Response
     * @throws ApiError
     */
    public static getSupportBack(
        campaignId: string,
        supportStatus: SupportStatus,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_SupportBack_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/support',
            query: {
                'campaign_id': campaignId,
                'support_status': supportStatus,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Support Read
     * @param requestBody
     * @returns SupportBack Successful Response
     * @throws ApiError
     */
    public static modifySupportRead(
        requestBody: ReadSupportData,
    ): CancelablePromise<SupportBack> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/support/read',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Support Status
     * @param requestBody
     * @returns SupportBack Successful Response
     * @throws ApiError
     */
    public static modifySupportStatus(
        requestBody: StatusSupportData,
    ): CancelablePromise<SupportBack> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/support/status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
