/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CampaignTagBack } from '../models/CampaignTagBack';
import type { ModifyCampaignBackTag } from '../models/ModifyCampaignBackTag';
import type { TagBack } from '../models/TagBack';
import type { TagData } from '../models/TagData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagBackService {

    /**
     * Get Tags
     * @returns TagBack Successful Response
     * @throws ApiError
     */
    public static getTags(): CancelablePromise<Array<TagBack>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/tag',
        });
    }

    /**
     * Modify Tag
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyTag(
        requestBody: TagData,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/tag',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Campaign Tag
     * @param requestBody
     * @returns CampaignTagBack Successful Response
     * @throws ApiError
     */
    public static modifyCampaignTag(
        requestBody: ModifyCampaignBackTag,
    ): CancelablePromise<CampaignTagBack> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/tag/campaign',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
