/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_modify_picture } from '../models/Body_modify_picture';
import type { User } from '../models/User';
import type { UserContact } from '../models/UserContact';
import type { UserCreate } from '../models/UserCreate';
import type { UserIdentity } from '../models/UserIdentity';
import type { UserPro } from '../models/UserPro';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Create User
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    public static createUser(
        requestBody: UserCreate,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User
     * @returns User Successful Response
     * @throws ApiError
     */
    public static getUser(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/me',
        });
    }

    /**
     * Modify Picture
     * @param formData
     * @returns string Successful Response
     * @throws ApiError
     */
    public static modifyPicture(
        formData: Body_modify_picture,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/picture',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Picture
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static removePicture(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/picture',
        });
    }

    /**
     * Modify Identity
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyIdentity(
        requestBody: UserIdentity,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/identity',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Pro
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyPro(
        requestBody: UserPro,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/pro',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Contact
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyContact(
        requestBody: UserContact,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Email
     * @param email
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyEmail(
        email: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/email',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Lang
     * @param lang
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyLang(
        lang: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/lang',
            query: {
                'lang': lang,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static deleteUser(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users',
        });
    }

}
