/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExportBackService {

    /**
     * Get Users Export
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getUsersExport(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/export/users',
        });
    }

    /**
     * Get Orders Export
     * @param campaignId
     * @param orderNumber
     * @param status
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getOrdersExport(
        campaignId: string,
        orderNumber: string,
        status: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/export/orders',
            query: {
                'campaign_id': campaignId,
                'order_number': orderNumber,
                'status': status,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
