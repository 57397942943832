import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
} from '@mui/material';
import React, {useContext, useState} from 'react';
import {useTranslation} from 'next-i18next';
import {CustomButton} from '../components/common/button';
import {Popup} from '../components/common/modal';

type ConfirmationProps = {
    text: string;
    title?: string;
    alert?: boolean;
    onYes?: () => void;
    onNo?: () => void;
    yesText?: string;
    noText?: string;
};

export function ConfirmationProvider(props: {children: React.ReactNode}) {
    const {i18n, t} = useTranslation('common');
    const [open, setOpen] = useState<boolean>(false);
    const [confirmationProps, setConfirmationProps] = useState<ConfirmationProps | null>(null);

    function close() {
        setOpen(false);

        // console.log('confirmationProps.alert = ', confirmationProps.alert);
        // console.log('confirmationProps.alert ?? false = ', confirmationProps.alert ?? false);

        if (confirmationProps.alert ?? false) {
            confirmationProps.onYes?.();
        }
    }

    function confirm(confirmationProps: ConfirmationProps) {
        // console.log('confirm');
        setConfirmationProps(confirmationProps);
        setOpen(true);
    }

    function yes() {
        // console.log('yes');
        confirmationProps.onYes?.();
        close();
    }

    function no() {
        // console.log('no');
        confirmationProps.onNo?.();
        close();
    }

    const value = {
        confirm,
    };

    const alert = confirmationProps?.alert ?? false;

    return (
        <ConfirmationContext.Provider value={value}>
            {props.children}
            {confirmationProps && (
                <Popup
                    open={open}
                    onClose={close}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    title={confirmationProps.title ?? 'Confirmation'}
                >
                    <>
                        <DialogContent>
                            <DialogContentText>{confirmationProps.text}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <CustomButton onClick={yes}>
                                {confirmationProps.yesText ?? t('word.yes')}
                            </CustomButton>
                            {!alert && (
                                <CustomButton onClick={no}>
                                    {confirmationProps.noText ?? t('word.no')}
                                </CustomButton>
                            )}
                        </DialogActions>
                    </>
                </Popup>
            )}
        </ConfirmationContext.Provider>
    );
}

const DEFAULT_CONTEXT_VALUE: ConfirmationContextInfo = {
    confirm: (confirmationProps: ConfirmationProps) => {},
};

export type ConfirmationContextInfo = {
    confirm: (confirmationProps: ConfirmationProps) => void;
};

export const ConfirmationContext = React.createContext(DEFAULT_CONTEXT_VALUE);

export function useConfirmation() {
    return useContext(ConfirmationContext);
}
