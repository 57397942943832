import {Snackbar} from '@mui/material';
import React, {useContext, useState} from 'react';
import MuiAlert from '@mui/material/Alert';

export enum NotificationSeverity {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

export function NotificationProvider(props: {children: React.ReactNode}) {
    const [open, setOpen] = useState<boolean>(false);
    const [text, setText] = useState<string>('');
    const [severity, setSeverity] = useState<NotificationSeverity>(
        NotificationSeverity.SUCCESS
    );

    function display(message: string, type?: NotificationSeverity) {
        setText(message);
        setSeverity(type ?? NotificationSeverity.SUCCESS);
        setOpen(true);
    }

    const value = {
        display,
    };

    return (
        <NotificationContext.Provider value={value}>
            {props.children}
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                <MuiAlert
                    onClose={() => setOpen(false)}
                    severity={severity}
                    sx={{width: '100%'}}
                >
                    {text}
                </MuiAlert>
            </Snackbar>
        </NotificationContext.Provider>
    );
}

const DEFAULT_CONTEXT_VALUE: NotificationContextInfo = {
    display: (message: string) => {},
};

export type NotificationContextInfo = {
    display: Function;
};

export const NotificationContext = React.createContext(DEFAULT_CONTEXT_VALUE);

export function useNotification() {
    return useContext(NotificationContext);
}
