/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Display {
    BASIC = 'BASIC',
    BEST_SELLER = 'BEST_SELLER',
}
