/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrderBackCancel } from '../models/OrderBackCancel';
import type { OrderBackDetails } from '../models/OrderBackDetails';
import type { OrderBackStatus } from '../models/OrderBackStatus';
import type { Page_OrderBackOverview_ } from '../models/Page_OrderBackOverview_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderBackService {

    /**
     * Get Orders Back
     * @param campaignId
     * @param orderNumber
     * @param status
     * @param page
     * @param size
     * @returns Page_OrderBackOverview_ Successful Response
     * @throws ApiError
     */
    public static getOrdersBack(
        campaignId: string,
        orderNumber: string,
        status: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_OrderBackOverview_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/orders',
            query: {
                'campaign_id': campaignId,
                'order_number': orderNumber,
                'status': status,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Order Back
     * @param orderId
     * @returns OrderBackDetails Successful Response
     * @throws ApiError
     */
    public static getOrderBack(
        orderId: string,
    ): CancelablePromise<OrderBackDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/order',
            query: {
                'order_id': orderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Order Status
     * @param requestBody
     * @returns OrderBackDetails Successful Response
     * @throws ApiError
     */
    public static changeOrderStatus(
        requestBody: OrderBackStatus,
    ): CancelablePromise<OrderBackDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel Order
     * @param requestBody
     * @returns OrderBackDetails Successful Response
     * @throws ApiError
     */
    public static cancelOrder(
        requestBody: OrderBackCancel,
    ): CancelablePromise<OrderBackDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/order/cancel',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
