import {useMediaQuery} from '@mui/material';

export type MediaQueryResponse = {
    isMobile: boolean;
    isTablet: boolean;
    isLaptop: boolean;
    isWide: boolean /* = lame property name */;
};

export function useResponsive(): MediaQueryResponse {
    const isMobile = useMediaQuery('(max-width: 450px)');
    const isTablet = useMediaQuery('(min-width: 450px) and (max-width: 768px)');
    const isLaptop = useMediaQuery('(min-width: 768px) and (max-width: 1280px)');
    const isWide = useMediaQuery('(min-width: 1024px)');
    return {isMobile, isTablet, isLaptop, isWide};
}
