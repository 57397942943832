import {Box, Divider, Typography, useTheme} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'next-i18next';
import {MyCustomLink, MyLink} from '../common/button';
import {LogoFooter, Paypal} from './logo';
import PopupCookie from './popupCookie';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Link from 'next/link';
import {Colors} from '../common/colors';
import TiktokIcon from '../icons/tiktok';

export default function Footer() {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    // const [slogan, setSlogan] = useState<string>('');
    const {t} = useTranslation('common');
    const [popupCookieVisible, setPopupCookieVisible] = useState<boolean>(false);
    const marginTop = {xs: '10px', md: '0px'};

    // useEffect(() => {
    //     setSlogan(SLOGANS[getRandomInt(SLOGANS.length - 1)]);
    // }, []);

    return (
        <Box style={{marginTop: '150px'}} sx={{bgcolor: 'secondary.main'}}>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: {xs: 'column', md: 'row'},
                    justifyContent: 'space-evenly',
                    padding: {xs: '10px', md: '50px'},
                    textAlign: 'center',
                }}
            >
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                >
                    <Link href="/">
                        <LogoFooter />
                    </Link>
                    <Typography alignSelf={'center'}>{t('tips.preorder')}</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} sx={{marginTop}}>
                    <MyLink href="/help/faq" title={t('title.faq')} />
                    <MyLink href="/help/support" title={t('title.support')} />
                </Box>
                <Box display={'flex'} flexDirection={'column'} sx={{marginTop}}>
                    <MyCustomLink
                        onClick={() => setPopupCookieVisible(true)}
                        title={t('link.cookie')}
                    />
                    <MyLink href="/info/cgv" title={t('title.cgv')} />
                    <MyLink href="/info/cgu" title={t('title.cgu')} />
                    <MyLink href="/info/privacy-policy" title={t('title.privacy_policy')} />
                    <MyLink href="/info/mention" title={t('title.mention')} />
                    <PopupCookie
                        visible={popupCookieVisible}
                        setVisible={setPopupCookieVisible}
                    />
                </Box>
            </Box>
            <Divider orientation="horizontal" flexItem />
            <FounderFooter />
            <Divider orientation="horizontal" flexItem />
            <NetworkList />
            <Divider orientation="horizontal" flexItem />
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                padding={'15px'}
                onClick={() => window.open('https://www.paypal.com/')}
            >
                <Paypal />
                <Typography alignSelf={'center'} style={{cursor: 'pointer'}}>
                    {t('info.paypal')}
                </Typography>
            </Box>
            <Divider orientation="horizontal" flexItem />
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                padding={'15px'}
            >
                <Typography>&copy; {new Date(Date.now()).getFullYear()} KUNEKUNE</Typography>
                <PoweredByKuneTech />
                <Typography>v1.0</Typography>
            </Box>
        </Box>
    );
}

function PoweredByKuneTech() {
    const {i18n} = useTranslation();
    const lang = i18n.language == 'en' ? 'en-GB' : i18n.language;
    return (
        <Typography>
            Powered by{' '}
            <Link
                href={`https://kune.tech/${lang}/welcome`}
                style={{textDecorationLine: 'none'}}
                target="_blank"
                passHref
            >
                <Box
                    component="span"
                    sx={{
                        color: Colors.firstHover,
                        textDecorationLine: 'none',
                        fontWeight: 'bold',
                        '&:hover': {color: Colors.first},
                        '&:visited': {color: Colors.first},
                    }}
                >
                    Kune.tech
                </Box>
            </Link>
        </Typography>
    );
}

export function NetworkList() {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';

    return (
        <Box
            display="flex"
            flexDirection={'row'}
            justifyContent="center"
            style={{flexWrap: 'wrap'}}
        >
            <Box sx={{alignSelf: 'center', margin: '20px'}}>
                <a
                    aria-label="Youtube"
                    href="https://www.youtube.com/@KuneKune_fr"
                    target="_blank"
                    rel="noreferrer"
                >
                    <YouTubeIcon fontSize="large" sx={{color: isDark ? 'white' : 'black'}} />
                </a>
            </Box>
            <Box sx={{alignSelf: 'center', margin: '20px'}}>
                <a
                    aria-label="X (Twitter)"
                    href="https://twitter.com/KuneKune_fr"
                    target="_blank"
                    rel="noreferrer"
                >
                    <TwitterIcon fontSize="large" sx={{color: isDark ? 'white' : 'black'}} />
                </a>
            </Box>
            <Box sx={{alignSelf: 'center', margin: '20px'}}>
                <a
                    aria-label="Instagram"
                    href="https://www.instagram.com/kunekune.fr/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <InstagramIcon fontSize="large" sx={{color: isDark ? 'white' : 'black'}} />
                </a>
            </Box>
            <Box sx={{alignSelf: 'center', margin: '20px'}}>
                <a
                    aria-label="Facebook"
                    href=" https://www.facebook.com/kunekune.fr/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <FacebookIcon fontSize="large" sx={{color: isDark ? 'white' : 'black'}} />
                </a>
            </Box>
            <Box sx={{alignSelf: 'center', margin: '20px'}}>
                <a
                    aria-label="TikTok"
                    href=" https://www.tiktok.com/@kunekune.fr"
                    target="_blank"
                    rel="noreferrer"
                >
                    <TiktokIcon fontSize="large" sx={{color: isDark ? 'white' : 'black'}} />
                </a>
            </Box>
            <a
                aria-label="Newsletter"
                style={{
                    fontSize: 18,
                    alignSelf: 'center',
                    margin: '20px',
                    color: isDark ? 'white' : 'black',
                }}
                href="https://kunekune.miweo.com/"
                target="_blank"
                rel="noreferrer"
            >
                Newsletter
            </a>
        </Box>
    );
}

export function FounderFooter() {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignitems: 'center',
                flex: 1,
                flexDirection: 'row',
                flexWrap: 'wrap',
                margin: '10px',
            }}
        >
            <MyLink href="/founder/benzaie" title="Benzaie" />
            <MyLink href="/founder/bob-lennon" title="Bob Lennon" />
            <MyLink href="/founder/jdg" title="JDG" />
            <MyLink href="/founder/sheshounet" title="Sheshounet" />
            <MyLink href="/founder/asenka" title="Asenka" />
        </Box>
    );
}
