/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BasketError {
    CAMPAIGN_UNAVAILABLE = 'CAMPAIGN_UNAVAILABLE',
    BASKET_EXPIRED = 'BASKET_EXPIRED',
}
