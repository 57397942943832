/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_send_support } from '../models/Body_send_support';
import type { FaqCategory } from '../models/FaqCategory';
import type { SupportCategory } from '../models/SupportCategory';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HelpService {

    /**
     * Get Faq
     * @returns FaqCategory Successful Response
     * @throws ApiError
     */
    public static getFaq(): CancelablePromise<Array<FaqCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/faq',
        });
    }

    /**
     * Get Faq By Campaign
     * @param campaignId
     * @returns FaqCategory Successful Response
     * @throws ApiError
     */
    public static getFaqByCampaign(
        campaignId: string,
    ): CancelablePromise<Array<FaqCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/faq/campaign',
            query: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Support
     * @returns SupportCategory Successful Response
     * @throws ApiError
     */
    public static getSupport(): CancelablePromise<Array<SupportCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/support',
        });
    }

    /**
     * Send Support
     * @param formData
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static sendSupport(
        formData: Body_send_support,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/support',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Support By Campaign
     * @param campaignId
     * @returns SupportCategory Successful Response
     * @throws ApiError
     */
    public static getSupportByCampaign(
        campaignId: string,
    ): CancelablePromise<Array<SupportCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/support/campaign',
            query: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
