import {User} from '../types';

export function getUserName(user: User) {
    if (user.first_name) {
        return user.first_name;
    } else {
        user.email;
    }
}

export function getUserEmailHash(user: User) {
    return hash(user.email);
}

function hash(str: string) {
    var hash = 0,
        i,
        chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function getUserPicture(user: User) {
    if (user.picture_url) {
        return user.picture_url;
    } else {
        return 'https://www.gravatar.com/avatar/' + getUserEmailHash(user) + '?d=retro';
    }
}

export function getPictureFromUrl(url: string | null | undefined) {
    if (url) {
        return url;
    } else {
        return 'https://www.gravatar.com/avatar/' + 'Anonymous' + '?d=retro';
    }
}
