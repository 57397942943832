/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Job {
    FORGOTTEN_BASKET = 'FORGOTTEN_BASKET',
    EXPIRED_RESERVATION = 'EXPIRED_RESERVATION',
}
