/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_modify_pack_picture } from '../models/Body_modify_pack_picture';
import type { CampaignPackOrder } from '../models/CampaignPackOrder';
import type { ChangeBack } from '../models/ChangeBack';
import type { CreatePackData } from '../models/CreatePackData';
import type { DeliveryBack } from '../models/DeliveryBack';
import type { DescriptionPackBack } from '../models/DescriptionPackBack';
import type { EditoPackBack } from '../models/EditoPackBack';
import type { OptionBack } from '../models/OptionBack';
import type { PackBack } from '../models/PackBack';
import type { PackBackOption } from '../models/PackBackOption';
import type { ParamPackBack } from '../models/ParamPackBack';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PackBackService {

    /**
     * Modify Pack Position
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyPackPosition(
        requestBody: CampaignPackOrder,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/pack/positon',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pack
     * @param packId
     * @returns PackBack Successful Response
     * @throws ApiError
     */
    public static getPack(
        packId: string,
    ): CancelablePromise<PackBack> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/pack',
            query: {
                'pack_id': packId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Pack
     * @param requestBody
     * @returns PackBack Successful Response
     * @throws ApiError
     */
    public static createPack(
        requestBody: CreatePackData,
    ): CancelablePromise<PackBack> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/pack',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Pack Picture
     * @param formData
     * @returns string Successful Response
     * @throws ApiError
     */
    public static modifyPackPicture(
        formData: Body_modify_pack_picture,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/pack/picture',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Pack Edito
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyPackEdito(
        requestBody: EditoPackBack,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/pack/edito',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Pack Description
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyPackDescription(
        requestBody: DescriptionPackBack,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/pack/description',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Pack Param
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyPackParam(
        requestBody: ParamPackBack,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/pack/param',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Pack Option
     * @param requestBody
     * @returns PackBackOption Successful Response
     * @throws ApiError
     */
    public static modifyPackOption(
        requestBody: OptionBack,
    ): CancelablePromise<PackBackOption> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/pack/option',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Pack Delivery
     * @param requestBody
     * @returns ChangeBack Successful Response
     * @throws ApiError
     */
    public static modifyPackDelivery(
        requestBody: DeliveryBack,
    ): CancelablePromise<ChangeBack> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/pack/delivery',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Pack Delivery
     * @param packDeliveryId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static deletePackDelivery(
        packDeliveryId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/back/pack/delivery',
            query: {
                'pack_delivery_id': packDeliveryId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
