const DEFAULT_LANG = 'fr';

type GenericLang = {
    lang: string;
};

export function getLocaleLabels<T extends GenericLang>(
    labels: T[] | null | undefined,
    lang: string
) {
    if (!labels) {
        return null;
    }

    const myLocale = labels.find((f) => f.lang == lang);
    if (myLocale) {
        return myLocale;
    } else {
        const defaultLocale = labels.find((f) => f.lang == DEFAULT_LANG);
        return defaultLocale;
    }
}
