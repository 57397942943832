/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailBasketBack } from '../models/EmailBasketBack';
import type { ReservationEmailBack } from '../models/ReservationEmailBack';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BasketBackService {

    /**
     * Get All Baskets
     * @returns EmailBasketBack Successful Response
     * @throws ApiError
     */
    public static getAllBaskets(): CancelablePromise<Array<EmailBasketBack>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/basket',
        });
    }

    /**
     * Get All Reservations
     * @returns ReservationEmailBack Successful Response
     * @throws ApiError
     */
    public static getAllReservations(): CancelablePromise<Array<ReservationEmailBack>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/reservation',
        });
    }

}
