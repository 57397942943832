import {Box, Typography} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {getAcceptation, setAcceptation} from '../../utils/cookie';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import {useTranslation} from 'next-i18next';
import {Popup} from '../common/modal';
import {CustomButton} from '../common/button';
import {Colors} from '../common/colors';
import {useResponsive} from '../../provider/MediaQueryProvider';

type PopupCookieProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
};

export default function PopupCookie({visible, setVisible}: PopupCookieProps) {
    const {isMobile} = useResponsive();
    const {t} = useTranslation('common');
    const [open, setOpen] = useState<boolean>(false);

    const load = useCallback(() => {
        const value = getAcceptation();
        if (value === null || value === undefined) {
            setOpen(true);
            setVisible(true);
        }
    }, [setVisible]);

    useEffect(() => {
        load();
    }, [load]);

    useEffect(() => {
        if (visible) {
            setOpen(true);
        }
    }, [visible]);

    async function accept() {
        setAcceptation('true');
        setOpen(false);
        setVisible(false);

        if (gtag) {
            gtag('consent', 'update', {
                ad_storage: 'granted',
                analytics_storage: 'granted',
            });
        }
    }

    async function refuse() {
        await setAcceptation('false');
        setOpen(false);
        setVisible(false);

        if (gtag) {
            gtag('consent', 'update', {
                ad_storage: 'denied',
                analytics_storage: 'denied',
            });
        }
    }

    async function close() {
        setOpen(false);
        setVisible(false);
    }

    const flexDirection = isMobile ? 'column' : 'row';

    return (
        <Popup
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            title={t('title.cookie')}
        >
            <Box padding="15px">
                <Box display="flex" flexDirection="row">
                    <SettingsOutlinedIcon />
                    <Typography fontWeight="bold" marginLeft="10px">
                        {t('title.usage')}
                    </Typography>
                </Box>
                <Typography marginTop="5px">{t('warning.cookie')}</Typography>
                <Box display="flex" flexDirection="row" marginLeft="20px" marginTop="10px">
                    <CheckCircleOutlineIcon sx={{color: Colors.secondHover}} />
                    <Typography marginLeft="10px">{t('warning.commercial')}</Typography>
                </Box>
                <Box display="flex" flexDirection="row" marginLeft="20px" marginTop="10px">
                    <CheckCircleOutlineIcon sx={{color: Colors.secondHover}} />
                    <Typography marginLeft="10px">{t('warning.target')}</Typography>
                </Box>
                <Box display="flex" flexDirection="row" marginTop="25px">
                    <ScheduleOutlinedIcon />
                    <Typography fontWeight="bold" marginLeft="10px">
                        {t('title.conservation')}
                    </Typography>
                </Box>
                <Typography marginTop="5px">{t('warning.conservation')}</Typography>
                <Box
                    display="flex"
                    flexDirection={flexDirection}
                    marginTop="25px"
                    justifyContent="space-evenly"
                >
                    <CustomButton
                        onClick={accept}
                        sx={{
                            color: 'primary',
                            borderColor: Colors.second,
                            backgroundColor: 'transparent',
                            '&:hover': {
                                borderColor: Colors.secondHover,
                                backgroundColor: Colors.secondHover,
                            },
                        }}
                    >
                        {t('action.accept')}
                    </CustomButton>
                    <CustomButton
                        onClick={refuse}
                        sx={{
                            color: 'primary',
                            borderColor: Colors.first,
                            backgroundColor: 'transparent',
                            '&:hover': {
                                borderColor: Colors.firstHover,
                                backgroundColor: Colors.firstHover,
                            },
                            marginTop: isMobile ? '10px' : '0px',
                        }}
                    >
                        {t('action.refuse')}
                    </CustomButton>
                </Box>
            </Box>
        </Popup>
    );
}
