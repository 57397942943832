import {Avatar, Button, Menu, MenuItem, useTheme} from '@mui/material';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useState} from 'react';
import {useTranslation} from 'next-i18next';
import {useResponsive} from '../../provider/MediaQueryProvider';
import {useUser} from '../../provider/UserProvider';
import {getUserName, getUserPicture} from '../../utils/user';
import {CustomButton} from '../common/button';
import {Colors} from '../common/colors';

export default function User() {
    const {t} = useTranslation('common');
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const {isMobile, isTablet, isLaptop} = useResponsive();
    const router = useRouter();
    const {dbUser, logout} = useUser();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function logoutAndClose() {
        logout();
        handleClose();
    }
    function login() {
        router.push('/login');
    }

    const name = dbUser ? getUserName(dbUser) : 'Anonymous';
    const picture = dbUser ? getUserPicture(dbUser) : null;

    return (
        <>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose} component={Link} href="/account?tab=profile">
                    {t('action.profile')}
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} href="/account?tab=orders">
                    {t('action.order')}
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} href="/account?tab=favorites">
                    {t('action.favorites')}
                </MenuItem>
                <MenuItem onClick={logoutAndClose} component={Link} href="/">
                    {t('action.logout')}
                </MenuItem>
            </Menu>
            {!dbUser && (
                <CustomButton
                    onClick={login}
                    sx={{
                        marginRight: '15px',
                        fontSize: isMobile ? '10px' : '12px',
                        padding: isTablet ? '6px' : isMobile ? '4px' : '8px',
                        height: '25px',
                        borderRadius: '12px',
                        fontWeight: 'normal',
                        borderColor: Colors.second,
                        backgroundColor: 'transparent',
                        color: isDark ? Colors.second : 'black',
                        '&:hover': {
                            backgroundColor: isDark ? Colors.grey : Colors.second,
                            boxShadow: 0,
                        },
                    }}
                >
                    {t('action.login')}
                </CustomButton>
            )}
            {dbUser && (
                <Button
                    id="basic-button"
                    aria-label="Profil"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <Avatar
                        aria-label="Avatar"
                        alt={name}
                        src={picture}
                        imgProps={{referrerPolicy: 'no-referrer'}}
                        sx={{width: isMobile ? 30 : 40, height: isMobile ? 30 : 40}}
                    />
                </Button>
            )}
        </>
    );
}
