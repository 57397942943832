/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Sale {
    STANDARD = 'STANDARD',
    TEMPORARY = 'TEMPORARY',
    PROFESSIONAL = 'PROFESSIONAL',
    PRIVATE = 'PRIVATE',
}
