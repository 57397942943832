import {getCookie, setCookie} from 'cookies-next';

const COOKIE_EXPIRATION = 60 * 24 * 30 * 6;
const COOKIE_KEY = 'localConsent';

export function getAcceptation() {
    return getCookie(COOKIE_KEY);
}

export function setAcceptation(acceptation: string) {
    setCookie(COOKIE_KEY, acceptation, {maxAge: COOKIE_EXPIRATION});
}
