/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SupportStatus {
    TODO = 'TODO',
    IGNORED = 'IGNORED',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
}
