import {Box, Typography, TypographyProps, useTheme} from '@mui/material';

import {Colors} from './colors';
import {useResponsive} from '../../provider/MediaQueryProvider';

export function Title(props: TypographyProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';

    const {isMobile, isTablet} = useResponsive();
    const fontSize = isMobile || isTablet ? 25 : 40;
    return (
        <Typography
            variant="h1"
            component="h1"
            fontWeight="bold"
            color={isDark ? Colors.somber : Colors.grey}
            fontSize={fontSize}
            textAlign="center"
            marginTop={'25px'}
            marginBottom={'5px'}
        >
            {props.children}
        </Typography>
    );
}

export function FirstHomeTitle(props: TypographyProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';

    const {isMobile, isTablet, isLaptop} = useResponsive();
    const fontSize = isMobile ? 25 : isTablet ? 30 : isLaptop ? 40 : 60;

    return (
        <Typography
            variant="h1"
            component="h1"
            fontWeight="bold"
            color={isDark ? 'white' : Colors.grey}
            fontSize={fontSize}
            textAlign="center"
            textTransform="uppercase"
        >
            {props.children}
        </Typography>
    );
}

export function CampaignTitle(
    props: TypographyProps & {overAnImage?: boolean; overDarkImage?: boolean}
) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const overImageColor = props.overDarkImage ? Colors.paypalSilver : Colors.grey;
    const color = props.overAnImage ? overImageColor : isDark ? Colors.somber : Colors.grey;
    return (
        <Typography
            variant="h1"
            component="h1"
            sx={{
                fontWeight: 'bold',
                color,
                fontSize: {xs: 25, md: 50},
                textAlign: 'center',
                marginTop: {xs: 2, md: 10},
                marginBottom: {xs: '8vh', md: 10},
                marginLeft: 5,
                marginRight: 5,
            }}
        >
            {props.children}
        </Typography>
    );
}

export function Centered(props: TypographyProps) {
    return (
        <Typography textAlign="center" {...props}>
            {props.children}
        </Typography>
    );
}

export function TabTitle(props: TypographyProps) {
    const {isMobile, isTablet} = useResponsive();
    const fontSize = isMobile || isTablet ? 20 : 30;
    return (
        <Typography
            variant="h2"
            component="h2"
            fontSize={fontSize}
            textAlign="center"
            marginTop={'20px'}
            marginBottom={'20px'}
        >
            {props.children}
        </Typography>
    );
}

export function ColumnTitle(props: TypographyProps) {
    const {isMobile, isTablet} = useResponsive();
    const fontSize = isMobile || isTablet ? 20 : 30;
    return (
        <Typography
            variant="h2"
            component="h2"
            fontSize={fontSize}
            marginBottom={'10px'}
            sx={props.sx}
        >
            {props.children}
        </Typography>
    );
}

type MultilineProps = {
    typo: TypographyProps;
    numberOfLine?: number;
    children: JSX.Element;
};

export function Multiline(props: MultilineProps) {
    let lineSx = {};

    if (props.numberOfLine) {
        lineSx = {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: props.numberOfLine.toString(),
            WebkitBoxOrient: 'vertical',
        };
    }

    const customSx = {
        ...props.typo.sx,
        ...lineSx,
    };

    return (
        <Typography {...props.typo} sx={customSx}>
            {props.children}
        </Typography>
    );
}

type MaxHeightProps = {
    typo: TypographyProps;
    max?: number;
    children: JSX.Element;
    className: string;
};

export function MaxHeight(props: MaxHeightProps) {
    const theme = useTheme();

    const gradient =
        theme.palette.mode == 'dark'
            ? 'linear-gradient(rgb(255, 255, 255, 0), ' + Colors.dark + ')'
            : 'linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255))';

    const HEIGHT = 50;
    const top = props.max - HEIGHT;

    let lineSx = {};

    if (props.max) {
        lineSx = {
            position: 'relative',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxHeight: props.max,
            '&::before': {
                content: '""',
                width: '100%',
                height: HEIGHT + 'px',
                position: 'absolute',
                left: '0',
                top: top + 'px',
                background: gradient,
            },
        };
    }

    const customSx = {
        ...props.typo.sx,
        ...lineSx,
    };

    return (
        <Box {...props.typo} sx={customSx} className={props.className}>
            {props.children}
        </Box>
    );
}

export function SectionTitle(props: TypographyProps) {
    const {isMobile, isTablet} = useResponsive();
    const fontSize = isMobile || isTablet ? 20 : 30;
    return (
        <Typography
            variant="h2"
            component="h2"
            fontSize={fontSize}
            textAlign="left"
            marginTop={'20px'}
            marginBottom={'20px'}
        >
            {props.children}
        </Typography>
    );
}

export function HomeTitle(props: TypographyProps) {
    const theme = useTheme();
    const isDark = theme.palette.mode == 'dark';
    const {isMobile, isTablet} = useResponsive();
    const fontSize = isMobile || isTablet ? 25 : 40;
    return (
        <Typography
            variant="h2"
            component="h2"
            fontWeight="bold"
            color={props.color ? props.color : isDark ? Colors.somber : Colors.grey}
            fontSize={fontSize}
            textAlign="center"
            marginTop={'25px'}
            marginBottom={'5px'}
            textTransform="uppercase"
        >
            {props.children}
        </Typography>
    );
}

export function FlexTitle(props: TypographyProps) {
    return (
        <Typography
            variant="h1"
            component="h1"
            fontWeight="bold"
            color={props.color ? props.color : 'black'}
            fontSize={props.fontSize ? props.fontSize : 30}
            textAlign={props.textAlign ? props.textAlign : 'left'}
        >
            {props.children}
        </Typography>
    );
}

export function Prose(props: Omit<TypographyProps, 'variant' | 'gutterBottom'>) {
    return <Typography variant="body1" gutterBottom {...props} />;
}

export function ProseSmall(props: Omit<TypographyProps, 'variant' | 'gutterBottom'>) {
    return <Typography variant="body2" gutterBottom {...props} />;
}
