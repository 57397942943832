import {
    createUserWithEmailAndPassword,
    reauthenticateWithCredential,
    GoogleAuthProvider,
    isSignInWithEmailLink,
    onAuthStateChanged,
    sendPasswordResetEmail,
    sendSignInLinkToEmail,
    signInWithEmailAndPassword,
    signInWithEmailLink,
    signInWithPopup,
    updateEmail,
    updatePassword,
    User as GoogleUser,
    EmailAuthProvider,
    UserCredential,
} from 'firebase/auth';
import React, {useContext, useEffect, useState} from 'react';
import {FirebaseError, initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {firebaseConfig, URL_CLIENT} from '../utils/settings';
import {Basket, CampaignLike, OpenAPI, User} from '../types';
import {useGetUser} from '../queries/userQueries';
import {useQueryClient} from 'react-query';
import {BASKET_GET, CAMPAIGN_GET_LIKE, ORDERS_GET, USER_GET} from '../queries/keys';
import {logEvent} from '../utils/tag';
import {signOut} from 'next-auth/react';

const provider = new GoogleAuthProvider();

// Initialize Firebase
const app = initializeApp(firebaseConfig, 'client');
const auth = getAuth(app);
auth.languageCode = 'fr';

export function UserProvider(props: {children: React.ReactNode}) {
    const queryClient = useQueryClient();
    const [googleUser, setGoogleUser] = useState<GoogleUser | null>(
        DEFAULT_CONTEXT_VALUE.googleUser
    );
    // const [dbUser, setDbUser] = useState<User | null>(DEFAULT_CONTEXT_VALUE.dbUser);

    const canGetUser = Boolean(googleUser) && !googleUser?.isAnonymous;
    const {data: dbUser, refetch} = useGetUser(canGetUser);
    // const createUser = useCreateUser();

    async function getToken() {
        const idToken = await googleUser?.getIdToken();
        return idToken ? idToken : null;
    }
    OpenAPI.TOKEN = getToken;

    useEffect(() => {
        onAuthStateChanged(auth, (gUser) => {
            if (gUser) {
                setGoogleUser(gUser);
                logEvent('login');
            } else {
                setGoogleUser(null);
                // setDbUser(null);

                cleanUserQueries();
            }
        });
    }, []);

    useEffect(() => {
        if (googleUser && !Boolean(dbUser)) {
            refetch();
        }
    }, [googleUser]);

    // console.log('USER PROVIDER GOOGLE USER = ', googleUser);
    // console.log('USER PROVIDER DB USER = ', dbUser);

    async function createGoogleUser(
        email: string,
        password: string
        // displayName: string,
        // firstName: string,
        // lastName: string
    ) {
        await createUserWithEmailAndPassword(auth, email, password);
        // const userCreate = {
        //     google_id: result.user.uid,
        //     email: email,
        //     display_name: displayName,
        //     first_name: firstName,
        //     last_name: lastName,
        // } as UserCreate;
        // const databaseUser = await createUser.mutateAsync(userCreate);
        // setDbUser(databaseUser);
    }

    async function loginByPassword(email: string, password: string) {
        await signInWithEmailAndPassword(auth, email, password);
    }

    async function loginByGoogle() {
        await signInWithPopup(auth, provider);
    }

    async function sendEmailLink(email: string) {
        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: URL_CLIENT + '/login-by-email',
            // This must be true.
            handleCodeInApp: true,
            // dynamicLinkDomain: firebaseEmulator ? 'localhost' : getDomain(),
        };
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        if (typeof window !== 'undefined') {
            window.localStorage.setItem('emailForSignIn', email);
        }
    }

    async function loginByEmail() {
        if (typeof window !== 'undefined' && !googleUser) {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                let email = window.localStorage.getItem('emailForSignIn');
                // console.log('loginByEmail :: email in storage = ', email);
                if (email !== null) {
                    // console.log('loginByEmail :: signInWithEmailLink :: email = ', email);
                    try {
                        const result = await signInWithEmailLink(
                            auth,
                            email,
                            window.location.href
                        );
                        // console.log(
                        //     'loginByEmail :: signInWithEmailLink :: result = ',
                        //     result
                        // );
                        window.localStorage.removeItem('emailForSignIn');
                        const user = result.user;
                        // console.log('loginByEmail :: signInWithEmailLink :: user = ', user);
                        setGoogleUser(user);
                        return true;
                    } catch (e) {
                        // console.log('loginByEmail :: error : ', e);
                        if (e instanceof FirebaseError) {
                            const firebaseError = e as FirebaseError;
                            // console.log(
                            //     'loginByEmail :: firebaseError.code = ',
                            //     firebaseError.code
                            // );
                            if (firebaseError.code == 'auth/email-already-in-use') {
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    function cleanUserQueries() {
        queryClient.removeQueries([USER_GET, BASKET_GET]);
        queryClient.setQueryData<CampaignLike[]>(CAMPAIGN_GET_LIKE, []);
        queryClient.setQueryData<Basket>(BASKET_GET, {items: []});
        queryClient.setQueryData<User>(USER_GET, null);
        queryClient.setQueryData<User>(ORDERS_GET, null);
    }

    async function logout() {
        await auth.signOut();
        setGoogleUser(null);
        signOut();
        cleanUserQueries();
    }

    async function resetPasword(email: string) {
        await sendPasswordResetEmail(auth, email);
    }

    async function updateUserPassword(password: string) {
        await updatePassword(googleUser, password);
    }

    async function updateUserEmail(email: string) {
        await updateEmail(googleUser, email);
    }

    async function deleteAccount() {
        await googleUser.delete();
        setGoogleUser(null);
        cleanUserQueries();
    }

    async function reAuthenticate(password: string) {
        const user = auth?.currentUser;
        if (user) {
            const credentials = EmailAuthProvider.credential(user.email, password);
            return await reauthenticateWithCredential(user, credentials);
        }
    }

    const value = {
        googleUser,
        dbUser,
        createGoogleUser,
        loginByPassword,
        logout,
        loginByGoogle,
        sendEmailLink,
        loginByEmail,
        resetPasword,
        updateUserPassword,
        updateUserEmail,
        deleteAccount,
        reAuthenticate,
    };

    return <UserContext.Provider value={value} {...props} />;
}

const DEFAULT_CONTEXT_VALUE: UserContextInfo = {
    googleUser: null,
    dbUser: null,
    createGoogleUser: (email: string, password: string, firstName: string, lastName: string) =>
        new Promise(() => {}),
    loginByPassword: (email: string, password: string) => {},
    logout: () => {},
    loginByGoogle: () => {},
    sendEmailLink: (email: string) => {},
    loginByEmail: () => {},
    resetPasword: (email: string) => {},
    updateUserPassword: (password: string) => {},
    updateUserEmail: (email: string) => {},
    deleteAccount: async () => {},
    reAuthenticate: (password: string) => undefined,
};

export type UserContextInfo = {
    googleUser: GoogleUser | null;
    dbUser: User | null;
    createGoogleUser: Function;
    loginByPassword: Function;
    logout: Function;
    loginByGoogle: Function;
    sendEmailLink: Function;
    loginByEmail: Function;
    resetPasword: Function;
    updateUserPassword: Function;
    updateUserEmail: Function;
    deleteAccount: () => Promise<void>;
    reAuthenticate: (password: string) => Promise<UserCredential | undefined>;
};

export const UserContext = React.createContext(DEFAULT_CONTEXT_VALUE);

export function useUser() {
    return useContext(UserContext);
}
