import {Basket, CampaignDetails, CampaignPack, OrderOverview, Pack} from '../types';
import {getLocaleLabels} from './lang';

type LogEvent = 'sign_up' | 'login';

export function logEvent(event: LogEvent) {
    if (gtag) {
        gtag('event', event);
    }
}

type ShareMethod = 'Twitter' | 'Facebook' | 'LinkedIn' | 'Whatsapp';

export function logShare(method: ShareMethod) {
    if (gtag) {
        gtag('event', 'share', {method: method});
    }
}

export function logPurchase(order: OrderOverview, basket: Basket, campaign: CampaignPack) {
    if (gtag) {
        let items = [];
        for (const p of basket.items) {
            const pack: Pack = campaign.packs.find((t) => t.pack_id == p.pack_id);
            if (pack) {
                const locale = getLocaleLabels(pack.labels, 'fr');
                const name = locale.name;
                const item = {
                    item_id: p.pack_id,
                    item_name: name,
                    price: pack.price,
                    quantity: p.quantity,
                };
                items.push(item);
            }
        }

        gtag('event', 'purchase', {
            transaction_id: order.order_id,
            value: order.total_amount,
            shipping: order.delivery_amount,
            currency: 'EUR',
            items: items,
        });
    }
}

export function logModifyCart(pack: Pack, quantity: number) {
    if (quantity == 0) {
        removeFromCart(pack);
    } else {
        addToCart(pack, quantity);
    }
}

function addToCart(pack: Pack, quantity: number) {
    if (gtag) {
        const locale = getLocaleLabels(pack.labels, 'fr');
        const name = locale.name;
        gtag('event', 'add_to_cart', {
            currency: 'EUR',
            value: pack.price,
            items: [
                {
                    item_id: pack.pack_id,
                    item_name: name,
                    currency: 'EUR',
                    price: pack.price,
                    quantity: quantity,
                },
            ],
        });
    }
}

function removeFromCart(pack: Pack) {
    if (gtag) {
        const locale = getLocaleLabels(pack.labels, 'fr');
        const name = locale.name;
        gtag('event', 'remove_from_cart', {
            currency: 'EUR',
            value: pack.price,
            items: [
                {
                    item_id: pack.pack_id,
                    item_name: name,
                    currency: 'EUR',
                    price: pack.price,
                    quantity: 1,
                },
            ],
        });
    }
}
